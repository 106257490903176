import React from "react"
import BlockContent from "@sanity/block-content-to-react"

import YoutubeEmbed from "~components/youtube/youtube"

const serializers = {
  types: {
    youtube: (value) => {
      if(value.node && value.node.youtubeOverride){
        return (<div className="mb--1 mt--1"><YoutubeEmbed embed={value.node.youtubeOverride} /></div>)
      }else{
        return <div></div>
      }
    }
  },
  marks: {
    externalLink: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark
      return blank ? (
        <a href={href} target="_blank" rel="noopener noreferrer" className="inline_link">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      )
    },
  },

}

const PortableText = ({ blocks }) => (
  <BlockContent blocks={blocks} serializers={serializers} />
)

export default PortableText
